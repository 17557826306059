// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"4c4e1bb03da9aab8dd548f88fabf2aaba49e0c15"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { sentryConfigShared } from './sentry.config.shared';

Sentry.init({
  ...sentryConfigShared,

  tracePropagationTargets: ['localhost', /\.herokuapp.com/, /\.raylo\.com/],

  // Add optional integrations for additional features
  integrations: [
    /** @url https://github.com/getsentry/sentry-javascript/issues/12706#issuecomment-2199473785 */
    // eslint-disable-next-line import/namespace
    Sentry.browserTracingIntegration(),
    ...(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ENABLED === 'true'
      ? [
          /** @url https://github.com/getsentry/sentry-javascript/issues/12706#issuecomment-2199473785 */
          // eslint-disable-next-line import/namespace
          Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: true,
            blockAllMedia: false,
          }),
        ]
      : []),
  ],

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE_FLOAT
    ? parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE_FLOAT)
    : 1.0,

  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // Outlook safe-link crawlers
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    // "Block third-party cookies and site data" checkbox is set
    "Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document",
  ],

  denyUrls: [
    // netlify
    'https://ingesteer.services-prod.nsvcs.net/rum_collection',
    // clarity
    'https://p.clarity.ms/collect',
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // safari webkit
    /@webkit-masked-url/,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    /\/gtag\/js/i,
  ],
});
